@import "nlib/config";

.dashboardPage {
  .counters {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1 * $contentDefaultIndent;
    float: auto;
  }
  .cards {
    flex: auto;
    align-items: stretch;
    .card {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    > div {
      + div {
        margin-left: $contentDefaultIndent * 2;
      }
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $contentDefaultIndent * 3;
    a {
      text-decoration: none;
    }
    .title {
      font-size: 1rem;
      svg {
        font-size: 1.25rem;
      }
    }
  }
}

[data-mobile] {
  @media (max-width: $viewportSizeSmall) { // 576px
    .dashboardPage {
      .header {
        margin-bottom: $contentDefaultIndent * 4;
      }
    }
  }

  .dashboardPage {
    .content {
      max-width: $maxMobileContainerWidth;
    }
  }
}
