@import "nlib/config";

.counter {
  display: block;
  flex: 1;
  padding: 0 $contentDefaultIndent $contentDefaultIndent * 2;
  a {
    display: block;
    height: 100%;
    padding-top: $contentDefaultIndent * 2.5;
    padding-bottom: $contentDefaultIndent * 2.5;
    overflow: hidden;
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  .icon {
    width: fit-content;
    padding: $contentDefaultIndent * 1.25;
    border-radius: $contentDefaultIndent / 2;
    background-color: $uiBackgroundColor;
    svg {
      display: block;
      font-size: 1.25rem;
    }
  }
  .number {
    display: flex;
    min-height: 2rem;
    margin-top: $contentDefaultIndent * 2.5;
    font-size: 2rem;
    line-height: 1;
    span,
    small {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    small {
      height: 1.1em;
      margin-top: 14px;
      margin-left: $contentDefaultIndent;
      color: $uiNeutralColor;
      font-size: 0.9375rem;
    }
  }
  .desc {
    margin-top: $contentDefaultIndent * 1.5;
  }
  &.loading {
    .number {
      span,
      small {
        display: inline-block;
        animation: opacity 2s infinite;
        border-radius: $contentDefaultIndent / 2;
        background-color: $uiBackgroundColor;
      }
      span {
        width: 40px;
        height: 1em;
      }
      small {
        width: 8em;
      }
    }
  }
  &.primary {
    .icon {
      background-color: lighten($uiHighlightColor, 54%);
      color: $uiHighlightColor;
    }
  }
  &.danger {
    .icon {
      background-color: lighten($uiNegativeColor, 39%);
      color: $uiNegativeColor;
    }
  }
  &.warning {
    .icon {
      background-color: lighten($uiWarningColor, 37%);
      color: $uiWarningColor;
    }
  }
  &.success {
    .icon {
      background-color: lighten($uiPositiveColor, 58%);
      color: $uiPositiveColor;
    }
  }
  &.royalBlue {
    .icon {
      background-color: lighten($uiSpecialColor, 36%);
      color: $uiSpecialColor;
    }
  }
  &.highlight {
    .icon {
      background-color: lighten($uiLightBlueColor, 31%);
      color: $uiLightBlueColor;
    }
  }
}

@keyframes opacity {
  50% {
    opacity: 0.5;
  }
}

[data-mobile] .counter {
  @media (max-width: $viewportSizeExtraLarge) { // 1200px
  }

  @media (max-width: $viewportSizeLarge) { // 992px
  }

  @media (max-width: $viewportSizeMedium) { // 768px
    width: 50%;
  }

  @media (max-width: $viewportSizeSmall) { // 576px
  }

  @media (max-width: $viewportSizeExtraSmall) { // 420px
    width: 50%;
    min-width: 162px;
    .card {
      padding: $contentDefaultIndent * 1.5;
      .number {
        font-size: 1.75rem;
        small {
          margin-top: 10px;
        }
      }
    }
  }
}
