@import "nlib/config";
@import "animations";

.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $contentDefaultIndent * 2;
  .left {
    display: flex;
    img {
      width: 1.5rem;
      max-width: 1.5rem;
      margin-right: $contentDefaultIndent;
    }
    .name {
      font-weight: 500;
    }
    .status {
      height: 1.25rem;
      font-size: 0.75rem;
      .reconnect {
        color: $uiNegativeColor;
        cursor: pointer;
      }
    }
  }
  .center {
    margin: 0 $contentDefaultIndent * 2;
    text-align: center;
    white-space: nowrap;
  }
  .right {
    display: flex;
    [data-wait] {
      animation: rotate 2s linear infinite;
    }
    svg + span {
      margin-left: 0.3em;
    }
    .syncInfo {
      display: flex;
      align-items: center;
      height: 100%;
      svg {
        font-size: 1.25rem;
      }
    }
  }
}

[data-mobile] .banner {
  @media (max-width: $viewportSizeExtraLarge) { // 1200px
  }

  @media (max-width: $viewportSizeLarge) { // 992px
  }

  @media (max-width: $viewportSizeMedium) { // 768px
    flex-wrap: wrap;
    .center {
      width: 100%;
      margin: $contentDefaultIndent * 2 0 0;
    }
    .center {
      text-align: left;
    }
    .right {
      margin: $contentDefaultIndent * 2 0 0;
    }
  }

  @media (max-width: $viewportSizeSmall) { // 576px
  }

  @media (max-width: $viewportSizeExtraSmall) { // 420px
  }
}
